import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import {
  Grid,
  Card,
  Box,
  Typography,
  makeStyles,
  Paper,
  Snackbar,
} from "@material-ui/core";
import { getData } from "../services/service";
import Fade from "@material-ui/core/Fade";
import Skeleton from "@material-ui/lab/Skeleton";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
  AppIcon: {
    borderRadius: "50%",
    maxWidth: 50,
    minWidth: 50,
    maxHeight: 50,
    overflow: "hidden",
    margin: 0,
    transition: "0.4s ease",
    transform: "translate3d(0, 0, 0);",
  },
  AppDescription: {
    color: "gray",
    // fontSize: 12,
    maxHeight: 156,
    minHeight: 63,
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: 10,
  },
  appItem: {
    transition: "0.3s ease",
  },
  cardLink: {
    "&:hover > *, &:active > *, &:focus > *": {
      transform: "scale(1.048)",
    },
    "&:hover img, &:active img, &:focus img": {
      transform: "rotateZ(-10deg) scale(1.098)",
    },
  },
}));

const IndexPage = () => {
  const [apps, setApps] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [loading, setLoading] = useState({ apps: false, info: false });
  const styles = useStyles();
  let mobile = false;

  if (typeof window !== "undefined") {
    mobile = !!window.navigator.userAgent.match(/iphone|ipad|android|mobile/gi);
  }

  if (mobile) {
    document.body.classList.add("mobile");
  }

  const alert = (text) => {
    setAlertText(text);
    setOpenAlert(true);
  };
  
  const closeAlert = () => {
    setOpenAlert(false);
    setTimeout(() => {
      setAlertText("");
    }, 1000);
    
  };

  useEffect(() => {
    setLoading(l => ({ ...l, apps: true }));
    getData("/apps", { url: window.location.hostname, mobile }).then(
      ({ error, msg, data: apps }) => {
        if (error) {
          alert(
            "Falha ao carregar aplicativos, tente novamente mais tarde! " +
              (msg ? " Resposta: " + msg : "")
          );
        }
        setApps(apps);
        setLoading(l => ({ ...l, apps: false }));
      }
    );

    // getData("/version.json", { url: (window.location.protocol + window.location.host) }, true).then(json => {
    //   console.info(json);
    // });
  }, []);

  return (
    <Layout>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openAlert}
        autoHideDuration={10000}
        onClose={closeAlert}
      >
        <Alert onClose={closeAlert} severity="error" variant="filled">
          {alertText}
        </Alert>
      </Snackbar>
      <Fade in timeout={500}>
        <Grid container spacing={2}>
          {loading.apps &&
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
              <Grid item xs={6} sm={4} md={3} xl={2} key={i}>
                <Card
                  elevation={4}
                  className={styles.appItem + " app-item-card"}
                >
                  <Box padding={2}>
                    <Grid container direction="column">
                      {/* container logo + title */}
                      <Grid
                        container
                        // xs={12}
                        direction="row"
                        alignItems="center"
                        className="container-logo-title"
                      >
                        <Grid item xs={4} className="grid-item-app__image">
                          <Skeleton
                            animation="wave"
                            variant="circle"
                            height={50}
                            width={50}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Skeleton animation="wave" height={40} />
                        </Grid>
                      </Grid>
                      {/* container description */}
                      <Grid item xs={12}>
                        <Skeleton animation="wave" />
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Grid>
            ))}
          {apps.length === 0 && !loading.apps && (
            <Grid item xs={12} key="nenhum app">
              <Paper elevation={6}>
                <Box padding={2}>
                  <Typography variant={"body2"}>
                    Nenhum aplicativo encontrado
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          )}
          {apps.map((app, i) => {
            if (app.title === "Hotsite") {
              return <></>;
            }

            let url = app.url;
            if (url.includes("?")) {
              url += `&modulo=${app.title}`;
            } else {
              url += `?modulo=${app.title}`;
            }

            if (app.gsistemaId) {
              url += `&gsistema=${app.gsistemaId}`;
            }

            if (app.grupo) {
              url += `&grupo=${app.grupo}`;
            }

            return (
              <Grid
                key={app.title + "-" + i}
                item
                xs={6}
                sm={4}
                md={3}
                lg={2}
                // xl={2}
                className="grid-item-app"
              >
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.cardLink}
                >
                  <Card
                    elevation={4}
                    className={styles.appItem + " app-item-card"}
                  >
                    <Box padding={1}>
                      <Grid container direction="column">
                        {/* container logo + title */}
                        <Grid
                          container
                          xs={12}
                          direction="row"
                          alignItems="center"
                          className="container-logo-title"
                        >
                          <Grid item xs={4} className="grid-item-app__image">
                            <Paper elevation={2} className={styles.AppIcon}>
                              <img
                                alt={"ícone do " + app.title}
                                src={
                                  app.icon &&
                                  require("../images/icons/" + app.icon)
                                }
                                style={{
                                  padding: app.iconPadding,
                                }}
                                className={styles.AppIcon}
                              ></img>
                            </Paper>
                          </Grid>
                          <Grid item xs={8} className="container-title">
                            <Typography variant={"body1"} className="title">
                              {app.title}
                            </Typography>
                          </Grid>
                        </Grid>
                        {/* container description */}
                        {app.description && (
                          <Grid item xs={12}>
                            <Typography
                              variant={"subtitle1"}
                              className={
                                styles.AppDescription + " app-description"
                              }
                            >
                              {app.description}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Card>
                </a>
              </Grid>
            );
          })}
        </Grid>
      </Fade>
    </Layout>
  );
};

export default IndexPage;
